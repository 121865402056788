<template>
  <div>
    <!--begin::User-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>

      <h3 class="card-label">
        {{ $t('branches.branch') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <h6 class="col-lg-12 pb-5">{{ $t('branches.basic_information') }}</h6>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('branches.branch_name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.branch_name" class="form-control" :class="validation && validation.branch_name ? 'is-invalid' : ''" :placeholder="$t('branches.branch_name')"/>
                <span v-if="validation && validation.branch_name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.branch_name[0] }}
                                    </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('branches.manager_name') }}</label>
                <div class="input-group">
                  <multiselect v-model="manager"
                               id="f_main"
                               :placeholder="$t('branches.manager_name')"
                               label="name"
                               track-by="id"
                               :options="users"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getUsers($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/users/users/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                  <span v-if="validation && validation.manager_name" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.manager_name[0] }}
                                </span>
                </div>
              </div>

            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <h6 class="col-lg-12 pb-5">{{ $t('branches.contact_information') }}</h6>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.mobile') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                  <!--                                        <input type="text" class="form-control" :class="validation && validation.mobile ? 'is-invalid' : ''" v-model="data.mobile" :placeholder="$t('branches.mobile')"/>-->
                  <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">{{ validation.mobile[0] }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.phone') }}</label>
                <div class="input-group">
                  <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>
                  <!--                                        <input type="text" class="form-control" :class="validation && validation.phone ? 'is-invalid' : ''" v-model="data.phone" :placeholder="$t('branches.phone')"/>-->
                  <span v-if="validation && validation.phone" class="fv-plugins-message-container invalid-feedback">{{ validation.phone[0] }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.email') }}</label>
                <div class="input-group">
                  <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" v-model="data.email" :placeholder="$t('branches.email')"/>
                  <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.fax') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.fax ? 'is-invalid' : ''" v-model="data.fax" :placeholder="$t('branches.fax')"/>
                  <span v-if="validation && validation.fax" class="fv-plugins-message-container invalid-feedback">{{ validation.fax[0] }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <h6 class="col-lg-12 pb-5">{{ $t('branches.address_information') }}</h6>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.country') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <!--                                        <select name="" id="countries" v-model="data.country_code2" class="custom-select" :class="validation && validation.country_code2 ? 'is-invalid' : ''">-->
                  <!--                                            <option v-for="row in countries" :value="row.code2" :key="row.code2">-->
                  <!--                                                {{ row.name }}-->
                  <!--                                            </option>-->
                  <!--                                        </select>-->
                  <multiselect v-model="country"
                               :class="validation && validation.country_code2 ? 'is-invalid' : ''"
                               :placeholder="$t('branches.country')"
                               label="name"
                               track-by="code2"
                               :options="countries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getCountries($event)"
                               :internal-search="false">
                  </multiselect>
                  <span v-if="validation && validation.country_code2" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.country_code2[0] }}
                                </span>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('branches.country') }}.</span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.city') }}</label>
                <!--                                        <select name="" id="cities" v-model="data.city" class="custom-select">-->
                <!--                                            <option v-for="row in cities" :value="row.id" :key="row.id">-->
                <!--                                                {{ row.name }}-->
                <!--                                            </option>-->
                <!--                                        </select>-->
                <div class="input-group">
                  <multiselect v-model="city"
                               :class="validation && validation.city ? 'is-invalid' : ''"
                               :placeholder="$t('branches.city')"
                               label="name"
                               track-by="id"
                               :options="cities"
                               :multiple="false"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" :href="data.country_code2?'/settings/cities/'+data.country_code2:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                  <span v-if="validation && validation.city" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.city[0] }}
                                </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.postal_code') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.postal_code ? 'is-invalid' : ''" v-model="data.postal_code" :placeholder="$t('branches.postal_code')"/>
                  <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.postal_code[0] }}
                                          </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.district') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.district ? 'is-invalid' : ''" v-model="data.district" :placeholder="$t('branches.district')"/>
                  <span v-if="validation && validation.district" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.district[0] }}
                                          </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.state') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.state ? 'is-invalid' : ''" v-model="data.state" :placeholder="$t('branches.state')"/>
                  <span v-if="validation && validation.state" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.state[0] }}
                                          </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.address_1') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.address1 ? 'is-invalid' : ''" v-model="data.address1" :placeholder="$t('branches.address_1')"/>
                  <span v-if="validation && validation.address1" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address1[0] }}
                                          </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branches.address_2') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.address2 ? 'is-invalid' : ''" v-model="data.address2" :placeholder="$t('branches.address_2')"/>
                  <span v-if="validation && validation.address2" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address2[0] }}
                                        </span>
                </div>
              </div>

              <div class="col-lg-6 pt-10">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 pt-5 mb-8">
                <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch>{{ $t('is_default') }}</b-form-checkbox>
              </div>

            </div>
          </div>

        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-branches",
  data() {
    return {
      mainRoute: 'settings/branches',
      mainRouteDependency: 'base/dependency',

      countries: [],
      cities: [],
      users: [],
      idEdit: this.$route.params.id ? this.$route.params.id : '',
      data: {
        branch_name: null,
        no_employee: null,
        no_inventories: null,
        // manager_name: null,
        is_active: true,
        is_default: false,
        country_code2: null,
        city: null,
        state: null,
        district: null,
        postal_code: null,
        address1: null,
        address2: null,
        email: null,
        mobile: null,
        phone: null,
        fax: null,
        manager_id: null,

      },
      isEditing: false,
      validation: null,
      manager: null,
      country: null,
      city: null,
      PlanMessage: null,
    };
  },
  watch: {
    "data.country_code2": function (val) {
      if (val) {
        this.getCities(val);
      }
    },
    "data.mobile": function (val) {
      if (val !== null && val.length < 6) {
        this.data.mobile = null;
      }
    },

    "data.phone": function (val) {
      if (val !== null && val.length < 6) {
        this.data.phone = null;
      }
    },
    manager: function (val) {
      if (val) {
        this.data.manager_id = val.id;
      } else {
        this.data.manager_id = null;
      }
    },
    country: function (val) {
      if (val) {
        this.data.country_code2 = val.code2;
      } else {
        this.data.country_code2 = null;
      }
    },
    city: function (val) {
      if (val) {
        this.data.city = val.id;
      } else {
        this.data.city = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.data.is_active = this.data.is_active?1:0;
      // this.data.is_default = this.data.is_default?1:0;
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/branches');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      // this.data.is_active = this.data.is_active?1:0;
      // this.data.is_default = this.data.is_default?1:0;
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/branches');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.manager = response.data.data.manager_relation;
        this.country = response.data.data.country;
        this.city = response.data.data.city_relation;


      });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
    },
    getDefaultCountries() {
      ApiService.get(`${this.mainRouteDependency}/default-country`).then((response) => {
        this.country = response.data.data;
      });
    },
    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },

    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/branches`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Branches"), route: '/settings/branches'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    if (this.idEdit) {
      this.getData();
    } else {
      this.getDefaultCountries();
      this.getPlanMessage();
    }
  },
};
</script>


